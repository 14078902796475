import React, { useEffect, useMemo, useState } from "react";
import Header from "../header/Header";

import "react-big-calendar/lib/css/react-big-calendar.css";
import "./Calendar.css";

import { Calendar, momentLocalizer } from "react-big-calendar";
import moment from "moment";

import ICAL from "ical.js";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";

const localizer = momentLocalizer(moment);

const CalendarView = () => {
	const [eventList, setEventList] = useState([]);

	const downloadCal = () => {
		fetch(
			"https://us-central1-sidewinder-3daf9.cloudfunctions.net/app/calendar?calid=MTQwMTM2NzE2MTQwMTM2N8nXVje968ByV4CkHEHJLRMPVcUza9r66m0VrvIUZB55-S-cRQyDOKc-v4P7uirwuhShw_4eslUvsyK6hpGn72M"
		).then((response) => {
			response.text().then((text) => {
				var events = [];

				var jcalData = ICAL.parse(text);
				var vcalendar = new ICAL.Component(jcalData);
				const vevents = vcalendar.getAllSubcomponents("vevent");
				var i = 0;
				for (const vevent of vevents) {
					var title = vevent.getFirstPropertyValue("summary");
					var description = vevent.getFirstPropertyValue("description");
					var start = vevent.getFirstPropertyValue("dtstart").toJSDate();
					var end = vevent.getFirstPropertyValue("dtend").toJSDate();

					let firstDayOfTodaysMonth = new Date();
					firstDayOfTodaysMonth.setDate(1);
					if (start >= firstDayOfTodaysMonth) {
						// If date is not before today
						events.push({ id: i, start, end, title, description });
					}
					i++;
				}
				events.sort((a, b) => (a.start > b.start ? 1 : -1));
				setEventList(events);

				return;
			});

			return;
		});
	};

	useEffect(() => {
		downloadCal();
		return () => {};
	}, []);

	function formatAMPM(date, isStartTime) {
		var hours = date.getUTCHours() + 17; // Time zone adjustment
		var ampm = <span className="ampm">{hours >= 12 ? " pm" : " am"}</span>;

		hours = hours % 12;
		hours = hours ? hours : 12; // the hour '0' should be '12'

		var minutes = date.getMinutes();

		var strTime = (
			<span>
				{hours}
				{minutes == 0 ? null : `:${minutes}`}
				{isStartTime ? null : ampm}
			</span>
		);
		return strTime;
	}

	function getTimes(start, end) {
		if (start.getUTCHours() == 7 && end.getUTCHours() == 7) {
			return null;
		}
		const startTime = formatAMPM(start, true);
		const endTime = formatAMPM(end);
		return (
			<span>
				{startTime}-{endTime}
			</span>
		);
	}

	function EventMonth(eventInfo) {
		console.log(eventInfo.event);
		return (
			<span className="calEvent">
				<p style={{ margin: 0, textWrap: "wrap", fontWeight: "bold" }}>
					{eventInfo.event.title}
				</p>
				{eventInfo.event.extendedProps.description ? (
					<p style={{ margin: 0, textWrap: "wrap" }}>
						{eventInfo.event.extendedProps.description}
					</p>
				) : null}
				<p style={{ margin: 0, textWrap: "wrap" }}>
					{getTimes(eventInfo.event.start, eventInfo.event.end)}
				</p>
			</span>
		);
	}

	return (
		<div>
			<Header active={"Watch"} />
			<div className="calwrapper">
				{eventList.length > 0 ? (
					<FullCalendar
						plugins={[dayGridPlugin]}
						initialView="dayGridMonth"
						events={eventList}
						hiddenDays={[0]}
						height={"100vh"}
						eventContent={EventMonth}
					/>
				) : (
					<h2>Loading Calendar...</h2>
				)}
			</div>
		</div>
	);
};

export default CalendarView;
