import "../App.css";
import "./Records.css";

import Footer from "../footer/Footer";
import Header from "../header/Header";
import { RecordList } from "./RecordInterfaces.tsx";
import { useState } from "react";

const categoryStrings = {
	set: "per Set",
	match: "in a match",
	season: "in a season",
	career: "",
};

const RecordData = ({ gender, entries, stat, category }) => {
	return (
		<div style={{ backgroundColor: "red", flexBasis: "50%" }}>
			<span>{gender}</span>
			<div
				style={{
					backgroundColor: "white",
					height: "780px",
					width: "1155px",
					display: "flex",
					justifyContent: "center",
					border: "20px solid black",
					borderRightWidth: gender === "Boys" ? "10px" : "20px",
					borderLeftWidth: gender === "Girls" ? "10px" : "20px",
					color: "black",
				}}
			>
				<div style={{ flex: 1 }}>
					<div
						style={{
							height: "170px",
							backgroundColor: "black",
							color: "white",
						}}
					>
						<h1 style={{ fontSize: "150px", lineHeight: "160px" }}>
							{category === "career" ? "Career" : ""} {stat}{" "}
							{categoryStrings[category]}
						</h1>
					</div>
					{entries.map((entry) => {
						return (
							<div className="recordData">
								<div
									className="recordPhoto"
									style={{
										backgroundImage: `url(${entry.image})`,
										height: "400px",
										borderRadius: "200px",
										border: "10px solid black",
									}}
								/>
								<div className="recordName">
									<h3 style={{ fontSize: "100px", lineHeight: "100px" }}>
										{entry.name}
									</h3>
									<div>
										<h2 style={{ fontSize: "80px", lineHeight: "90px" }}>
											{entries[0].amount}
										</h2>
									</div>
									{entry.dates.map((date) => {
										return <h5>{date}</h5>;
									})}
								</div>
							</div>
						);
					})}
				</div>
			</div>
		</div>
	);
};

const Gender = ({ stat, gender }) => {
	return (
		<div
			style={{
				display: "flex",
				flexWrap: "wrap",
				justifyContent: "flex-start",
			}}
		>
			{categories.map((category) => {
				return (
					<RecordData
						entries={RecordList[stat][category][gender]}
						gender={gender}
						category={category}
						stat={stat}
					/>
				);
			})}
		</div>
	);
};

const StatButton = ({ title, setStat, stat }) => {
	return (
		<button
			class="coachButton"
			onClick={() => setStat(title)}
			style={
				stat == title ? { color: "white", backgroundColor: "#232e63" } : {}
			}
		>
			<p>{title}</p>
		</button>
	);
};

const genders = ["Boys", "Girls"];
const statOptions = ["Kills", "Aces", "Digs", "Blocks", "Assists"];
const categories = ["set", "match", "season", "career"];

function RecordCard() {
	const [stat, setStat] = useState("Kills");
	return (
		<div>
			<div
				className="content"
				style={{
					display: "flex",
					flexDirection: "column",
					alignItems: "center",
				}}
			>
				<div>
					{statOptions.map((statOption) => {
						return (
							<StatButton title={statOption} setStat={setStat} stat={stat} />
						);
					})}
				</div>
				{genders.map((gender) => {
					return <Gender stat={stat} gender={gender} />;
				})}
			</div>
			<Footer />
		</div>
	);
}

export default RecordCard;
